// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function() {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());
if (typeof jQuery === 'undefined') {
  console.warn('jQuery hasn\'t loaded');
} else {
  console.log('jQuery has loaded');
}
// Place any jQuery/helper plugins in here.
$(function(){
  $('.bxslider').bxSlider({
    mode: 'fade',
    captions: true,
auto: true,
    controls: false
  });


  $('.bxslider2').bxSlider({
slideWidth: 200,
mode: 'horizontal',
pager: false,
minSlides: 2,
maxSlides: 6,
moveSlides: 1,
slideMargin: 14
  });

  $('.bxslider3').bxSlider({
slideWidth: 280,
mode: 'horizontal',
pager: false,
minSlides: 2,
maxSlides: 6,
moveSlides: 1,
slideMargin: 14
  });

});


 $('.slider-for').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.slider-nav'
});
$('.slider-nav').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  asNavFor: '.slider-for',
slideMargin: 2,

  focusOnSelect: true
});





function openbox(id,tt) {
              var div = document.getElementById(id);
              var tt_div = document.getElementById(tt);
              if(div.style.display == 'block') {
                div.style.display = 'none';
              }
              else {
                div.style.display = 'block';
              }
              }


